import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   Redirect,
// } from 'react-router-dom'
// import ReactGA from 'react-ga'

import './collapsible-styling.css'
import styled from 'styled-components'

// Redux
// import { connect } from 'react-redux'

// import Contact from './views/Contact/index'
// import TermsAndConditions from './views/TermsAndConditions/index'
// Views
// import Landing from './views/Landing/index'
// import Offerings from './views/Offerings/index'

// Components
// import Overlays from './components/Overlays/index'
// import LandingHeader from './components/LandingHeader'
// import NewFooter from './components/NewFooter'
import Spinner from './components/Spinner'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // const trackingId = 'G-B0TR18HSXT' // Replace with your Google Analytics tracking ID
    // ReactGA.initialize(trackingId)
    // ReactGA.pageview(window.location.pathname)
  }

  componentDidMount() {
    setTimeout(() => {
      window.location = 'https://publicsquare.io'
    }, 2500)
  }

  render() {
    return (
      <Main>
        <RedirectMsg>We have rebranded to PublicSquare!</RedirectMsg>
        <RedirectMsg2>Redirecting...</RedirectMsg2>
        <Spinner />
      </Main>
    )
    // return (
    //   <Router>
    //     <>
    //       <Overlays />

    //       <Switch>
    //         <Route
    //           exact
    //           path="/contact"
    //           render={(routeProps) => (
    //             <>
    //               <LandingHeader />
    //               <Contact {...routeProps} />
    //               <NewFooter />
    //             </>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/offerings"
    //           render={(routeProps) => (
    //             <>
    //               <LandingHeader />
    //               <Offerings {...routeProps} />
    //               <NewFooter />
    //             </>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/terms-and-conditions"
    //           render={(routeProps) => (
    //             <>
    //               <LandingHeader />
    //               <TermsAndConditions {...routeProps} />
    //               <NewFooter />
    //             </>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/"
    //           render={(routeProps) => (
    //             <>
    //               <LandingHeader page={'landing'} />
    //               <Landing {...routeProps} />
    //               <NewFooter />
    //             </>
    //           )}
    //         />
    //         <Redirect to="/" />
    //       </Switch>
    //     </>
    //   </Router>
    // )
  }
}

// const mapStateToProps = (state) => {
//   let stateBuilder = {}

//   return stateBuilder
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // Returns a promise
//   }
// }

// const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

export default hot(module)(App)

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #48515b;
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 10px;
  height: calc(100vh - 20px);
`

const RedirectMsg = styled.p`
  font-size: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  line-height: 55px;

  @media (max-width: 500px) {
    font-size: 35px;
    line-height: 35px;
    max-width: 90%;
    margin: 0;
  }
`

const RedirectMsg2 = styled.p`
  color: white;
  font-size: 30px;
  text-align: center;

  @media (max-width: 500px) {
    max-width: 90%;
    font-size: 24px;
    margin: 0;
    font-weight: 300;
  }
`
