// Action Types
export const SETUP_ON_LOAD = 'SETUP_ON_LOAD'
export const SHOW_AN_OVERLAY = 'SHOW_AN_OVERLAY'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const PASSWORD_RESET_REQUEST_STATUS = 'PASSWORD_RESET_REQUEST_STATUS'
export const PASSWORD_RESET_STATUS = 'PASSWORD_RESET_STATUS'
export const SAVED_USERS_SELECTED_TAGS = 'SAVED_USERS_SELECTED_TAGS'
export const GET_ALL_ISSUES = 'GET_ALL_ISSUES'
export const GET_ALL_SUBISSUES = 'GET_ALL_SUBISSUES'
export const GET_SUBISSUES_BY_CATEGORY = 'GET_SUBISSUES_BY_CATEGORY'
export const GET_ITEMS_BY_CATEGORY = 'GET_ITEMS_BY_CATEGORY'
export const GET_SINGLE_ISSUE_DETAILS = 'GET_SINGLE_ISSUE_DETAILS'
export const GET_SINGLE_SUBISSUE_DETAILS = 'GET_SINGLE_SUBISSUE_DETAILS'
export const GET_SINGLE_ITEM_DETAILS = 'GET_SINGLE_ITEM_DETAILS'
export const CLEAR_SINGLE_ITEM_DETAILS = 'CLEAR_SINGLE_ITEM_DETAILS'
export const GET_SINGLE_ITEM_PROPOSALS = 'GET_SINGLE_ITEM_PROPOSALS'
export const POST_NEW_ITEM_PROPOSAL = 'POST_NEW_ITEM_PROPOSAL'
export const CLOSE_NEW_ITEM_PROPOSAL = 'CLOSE_NEW_ITEM_PROPOSAL'
export const CLEAR_SINGLE_SUBISSUE_DETAILS = 'CLEAR_SINGLE_SUBISSUE_DETAILS'
export const GET_SINGLE_UPDATE_DETAILS = 'GET_SINGLE_UPDATE_DETAILS'
export const GET_PUBLIC_FEATURED_SUBISSUES = 'GET_PUBLIC_FEATURED_SUBISSUES'
export const GET_PUBLIC_FEATURED_ITEMS = 'GET_PUBLIC_FEATURED_ITEMS'
export const RENDER_USERS_DASHBOARD = 'RENDER_USERS_DASHBOARD'
export const GET_USERS_DASHBOARD = 'GET_USERS_DASHBOARD'
export const POST_SURVEY_RESPONSE_SUCCESS = 'POST_SURVEY_RESPONSE_SUCCESS'
export const POST_SUBISSUE_COMMENT_RESPONSE = 'POST_SUBISSUE_COMMENT_RESPONSE'
export const GET_ALL_WRITERS = 'GET_ALL_WRITERS'
export const GET_ALL_CASE_STUDIES = 'GET_ALL_CASE_STUDIES'
export const MAGIC_LOGIN_REQUEST_ERROR = 'MAGIC_LOGIN_REQUEST_ERROR'
export const MAGIC_LOGIN_ATTEMPT = 'MAGIC_LOGIN_ATTEMPT'
