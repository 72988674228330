// require('dotenv').config()
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
// Redux Reducer
import reducers from './redux/reducers'

// If Redux Dev Tools (Chrome extension) is present, use it to compose our enhancers
// If it's not present, just use Redux's compose function
// Necessary to hook up the extension to the Redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
