import {
  SETUP_ON_LOAD,
  SHOW_AN_OVERLAY,
  LOGIN_ERROR,
  SIGNUP_ERROR,
  PASSWORD_RESET_REQUEST_STATUS,
  PASSWORD_RESET_STATUS,
  MAGIC_LOGIN_REQUEST_ERROR,
  MAGIC_LOGIN_ATTEMPT,
  SAVED_USERS_SELECTED_TAGS,
  GET_ALL_ISSUES,
  GET_ALL_SUBISSUES,
  GET_SUBISSUES_BY_CATEGORY,
  GET_ITEMS_BY_CATEGORY,
  GET_SINGLE_ISSUE_DETAILS,
  GET_SINGLE_SUBISSUE_DETAILS,
  CLEAR_SINGLE_SUBISSUE_DETAILS,
  CLEAR_SINGLE_ITEM_DETAILS,
  GET_SINGLE_ITEM_DETAILS,
  GET_SINGLE_ITEM_PROPOSALS,
  POST_NEW_ITEM_PROPOSAL,
  CLOSE_NEW_ITEM_PROPOSAL,
  GET_SINGLE_UPDATE_DETAILS,
  GET_PUBLIC_FEATURED_SUBISSUES,
  GET_PUBLIC_FEATURED_ITEMS,
  GET_USERS_DASHBOARD,
  RENDER_USERS_DASHBOARD,
  POST_SURVEY_RESPONSE_SUCCESS,
  POST_SUBISSUE_COMMENT_RESPONSE,
  GET_ALL_WRITERS,
  GET_ALL_CASE_STUDIES
} from './actionTypes'

export const initialState = {
  commentResponses: {}
}

// Redux Reducers
function reducers(state = initialState, action) {
  switch (action.type) {
    case SETUP_ON_LOAD:
      return Object.assign({}, state, {
        userToken: action.token,
        userId: action.userId,
        userName: action.userName,
        overlay: false
      })

    case SHOW_AN_OVERLAY:
      return Object.assign({}, state, {
        overlay: action.overlay
      })

    case LOGIN_ERROR:
      return Object.assign({}, state, {
        loginError: action.msg
      })

    case SIGNUP_ERROR:
      return Object.assign({}, state, {
        signupError: action.msg
      })

    case MAGIC_LOGIN_REQUEST_ERROR:
      return Object.assign({}, state, {
        magicLoginRequestError: action.msg
      })

    case MAGIC_LOGIN_ATTEMPT:
      return Object.assign({}, state, {
        magicLoginAttemptResponse: action.msg
      })

    case PASSWORD_RESET_REQUEST_STATUS:
      return Object.assign({}, state, {
        resetPasswordRequest: action.msg
      })

    case PASSWORD_RESET_STATUS:
      return Object.assign({}, state, {
        resetPassword: true
      })

    case SAVED_USERS_SELECTED_TAGS:
      return Object.assign({}, state, {
        savedUserSelectedTags: true
      })

    case GET_ALL_SUBISSUES:
      return Object.assign({}, state, {
        subIssues: action.subIssues
      })

    case GET_SUBISSUES_BY_CATEGORY:
      return Object.assign({}, state, {
        subIssuesByCategory: action.subIssuesByCategory
      })

    case GET_ITEMS_BY_CATEGORY:
      return Object.assign({}, state, {
        itemsByCategory: action.itemsByCategory
      })

    case GET_ALL_ISSUES:
      return Object.assign({}, state, {
        allIssues: action.issues
      })

    case GET_SINGLE_ISSUE_DETAILS:
      return Object.assign({}, state, {
        singleIssueDetails: action.issueDetails
      })

    case GET_SINGLE_SUBISSUE_DETAILS:
      return Object.assign({}, state, {
        singleSubIssueDetails: action.subIssue
      })

    case CLEAR_SINGLE_SUBISSUE_DETAILS:
      return Object.assign({}, state, {
        singleSubIssueDetails: false
      })

    case CLEAR_SINGLE_ITEM_DETAILS:
      return Object.assign({}, state, {
        singleItemDetails: false,
        singleItemProposals: false
      })

    case GET_SINGLE_ITEM_DETAILS:
      return Object.assign({}, state, {
        singleItemDetails: action.item
      })

    case GET_SINGLE_ITEM_PROPOSALS:
      return Object.assign({}, state, {
        singleItemProposals: action.singleItemProposals
      })

    case POST_NEW_ITEM_PROPOSAL:
      return Object.assign({}, state, {
        newItemProposalResponse: 'Your proposal has been submit for approval.'
      })

    case CLOSE_NEW_ITEM_PROPOSAL:
      return Object.assign({}, state, {
        newItemProposalResponse: false
      })

    case GET_SINGLE_UPDATE_DETAILS:
      return Object.assign({}, state, {
        singleUpdateDetails: action.updateDetails
      })

    case RENDER_USERS_DASHBOARD:
      return Object.assign({}, state, {
        currentDashboardCard: action.currentCard,
        allDashboardCards: action.allCards,
        completeDashboardSurveys: action.completeSurveys
      })

    case GET_PUBLIC_FEATURED_SUBISSUES:
      return Object.assign({}, state, {
        publicFeaturedSubIssues: action.publicFeaturedSubIssues
      })

    case GET_PUBLIC_FEATURED_ITEMS:
      return Object.assign({}, state, {
        publicFeaturedItems: action.publicFeaturedItems
      })

    case GET_USERS_DASHBOARD:
      return Object.assign({}, state, {
        usersDashboard: action.usersDashboard
      })

    case POST_SURVEY_RESPONSE_SUCCESS:
      return Object.assign({}, state, {
        surveyResponses: {
          ...state.surveyResponses,
          [action.surveyResponseId]: action.surveyResponse
        }
      })

    case POST_SUBISSUE_COMMENT_RESPONSE:
      return Object.assign({}, state, {
        commentResponses: {
          ...state.commentResponses,
          [action.commentToken]: true
        }
      })

    case GET_ALL_WRITERS:
      return Object.assign({}, state, {
        allWriters: action.writers
      })

    case GET_ALL_CASE_STUDIES:
      return Object.assign({}, state, {
        allCaseStudies: action.allCaseStudies
      })

    default:
      return state
  }
}

export default reducers
